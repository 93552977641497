import * as S from './styles';

export default function InformationLine(props) {
    return (
        <S.ILContainer>
            <S.Label>
                {props.label}:
            </S.Label>
            {props.data &&
                <S.Data>
                    &nbsp; {props.data}
                </S.Data>
            }
            {props.endText &&
                <S.EndText>
                    &nbsp; {props.endText}
                </S.EndText>
            }
        </S.ILContainer>
    );
}