import styled from 'styled-components';

export const VariantSelectorContainer = styled.div`
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.scrollBarTrack};
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    }
    &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollBarHandle};
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    }
    height: 100%;
    overflow-y: auto;
`;

export const SectionContainer = styled.div`
    
`;

export const TilesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const SectionHeader = styled.h3`
    font-size: 1rem;
    font-weight: 500;
    color: ${props => props.theme.colors.informationText};
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
`;