import * as S from './styles';

export default function FormFieldNumber(props) {

    const InputStyle = props.positive ? S.Positive :
        props.negative ? S.Negative : S.Neutral

    return (
        <InputStyle
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
        />
    );
}