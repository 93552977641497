import React from 'react';

import { Button } from '../../atoms/Button';

import { commonMessages } from '../../../config';

import * as S from './styles';

export default class ProcessConfirmation extends React.Component {
    render() {
        return (
            <S.ProcessConfirmationContainer>
                <S.Header>{commonMessages.processConfirmation.header}</S.Header>
                <S.SubmitContainer>
                    <Button
                        nonConsumer
                        handleSubmit={() => this.props.handleSubmit()}
                        label={commonMessages.processConfirmation.returnToFormLabel}
                    />
                </S.SubmitContainer>
            </S.ProcessConfirmationContainer>
        );
    }
}