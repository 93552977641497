import styled from 'styled-components';

export const Primary = styled.button`
    color: ${props => props.theme.colors.buttonText};
    width: 100%;
    height: 34px;
    background: ${props => props.disabled ? props.theme.colors.buttonDisabledConsumer :
        props.theme.colors.buttonBackgroundPrimaryConsumer};
    margin: 0 auto;
    border: none;
    
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    &:hover{
        background: ${props => props.disabled ? props.theme.colors.buttonDisabledConsumer :
        props.theme.colors.buttonBackgroundHoverPrimaryConsumer};
        cursor: ${props => props.disabled ? "cursor" : "pointer"};
    }
    &:focus{
        outline: 0;
        border: 2px solid ${props => props.theme.colors.border};
    }
`;

export const NonConsumerPrimary = styled(Primary)`
    background: ${props => props.disabled ? props.theme.colors.buttonDisabledNonConsumer :
            props.theme.colors.buttonBackgroundPrimaryNonConsumer};
    margin: 0 auto;
    border-radius: ${props => props.theme.components.borderRadiusNonConsumer};
    &:hover{
        background: ${props => props.disabled ? props.theme.colors.buttonDisabledNonConsumer :
            props.theme.colors.buttonBackgroundHoverPrimaryNonConsumer};
    }
`;

export const Secondary = styled(Primary)`
    color: ${props => props.disabled ? props.theme.colors.white : props.theme.colors.buttonTextSecondary};
    background: ${props => props.disabled ? props.theme.colors.buttonDisabled :
        props.theme.colors.buttonBackgroundSecondary};
    border: 1px solid ${props => props.theme.colors.buttonBorderSecondary};
    &:hover{
        background: ${props => props.disabled ? props.theme.colors.buttonDisabled :
        props.theme.colors.buttonBackgroundHoverSecondary};
    }
`;