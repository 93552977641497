import styled from 'styled-components';

export const  UnprocessedReturnContainer = styled.button`
    width: 90%;
    height: 32px;
    background: ${props => props.theme.colors.buttonBackgroundSecondary};
    border: 1px solid ${props => props.theme.colors.border};
    margin-bottom: 20px;
    margin-left: 17.5px;
    padding-left: 10px;
    padding-right: 10px;
    &:hover {
        background: ${props => props.theme.colors.buttonBackgroundHoverSecondary};
        cursor: pointer;
    }
    &:focus{
        outline: 0;
    }
`;

export const FlexContainer = styled.div`
    display: flex;
`;

export const SubHeader = styled.div`
    font-size: 0.9rem;
    font-weight: 600;
    color: ${props => props.theme.colors.black};
    line-height: 1.5rem;
`;

export const InfoLineContainer = styled.div`
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15%;
`;