export const SAVE_RETURN_REQUEST = 'SAVE_RETURN_REQUEST';
export const SAVE_RETURN_SUCCESS = 'SAVE_RETURN_SUCCESS';
export const SAVE_RETURN_FAILURE = 'SAVE_RETURN_FAILURE';

export const FETCH_RETURN_REQUEST = 'FETCH_RETURN_REQUEST';
export const FETCH_RETURN_SUCCESS = 'FETCH_RETURN_SUCCESS';
export const FETCH_RETURN_FAILURE = 'FETCH_RETURN_FAILURE';

export const FETCH_RETURNS_REQUEST = 'FETCH_RETURNS_REQUEST';
export const FETCH_RETURNS_SUCCESS = 'FETCH_RETURNS_SUCCESS';
export const FETCH_RETURNS_FAILURE = 'FETCH_RETURNS_FAILURE';

export const PROCESS_RETURN_REQUEST = 'SAVE_RETURN_PROCESS_REQUEST';
export const PROCESS_RETURN_SUCCESS = 'SAVE_RETURN_PROCESS_REQUEST';
export const PROCESS_RETURN_FAILURE = 'SAVE_RETURN_PROCESS_REQUEST';