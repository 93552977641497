import styled from 'styled-components';

export const TileButton = styled.div`
    height: 40px;
    width: 40px;
    margin: 6px 10px 6px 0px;
    padding: 0;
    border: none;
    border: 1px solid ${props => props.theme.colors.containerBackground};
    background: ${props => props.theme.colors.white};
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    &:hover{
        cursor: ${props => props.disabled ? "cursor" : "pointer"};
    }
    &:focus{
        outline: 0;
    }
`;

export const TileNameContainer = styled.div`
    height: 100%;
    width: 100%;
    text-align: center;
    border: 1px solid ${props => props.active ? props.theme.colors.activeTileBorder : 
        props.theme.colors.border};
    background: ${props => props.active ? props.theme.colors.activeTileBackground :
        props.disabled ? props.theme.colors.tileButtonDisabled : 
        props.theme.colors.white};
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    opacity: ${props => props.disabled ? "0.5" : "1"};
    &:hover{
        background: ${props => props.disabled ? props.theme.colors.tileButtonDisabled :
            props.active ? props.theme.colors.activeTileBackground :
            props.theme.colors.tileHover};
    }
`;

export const TileImageContainer = styled.div`
    height: 100%;
    width: 100%;
`;

export const Image = styled.img`
    height: 100%;
    width: 100%;
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    border: 2px solid ${props => props.active ? props.theme.colors.activeTileBorder : 
        props.theme.colors.containerBackground};
    opacity: ${props => props.disabled ? "0.5" : "1"};
`;

export const Name = styled.h4`
    margin-top: 34%;
    cursor: ${props => props.disabled ? "default" : "pointer"};
    font-size: ${props => props.theme.typography.infoFontSize};
`;
