import { post } from '../services';
import { backendWebhook } from '../../config';
import {
    SAVE_RETURN_REQUEST,
    SAVE_RETURN_SUCCESS,
    SAVE_RETURN_FAILURE,
    FETCH_RETURN_REQUEST,
    FETCH_RETURN_SUCCESS,
    FETCH_RETURN_FAILURE,
    FETCH_RETURNS_REQUEST,
    FETCH_RETURNS_SUCCESS,
    FETCH_RETURNS_FAILURE,
    PROCESS_RETURN_REQUEST,
    PROCESS_RETURN_SUCCESS,
    PROCESS_RETURN_FAILURE
} from './constants';

export function saveReturnRequestAct(data) {
    return { type: SAVE_RETURN_REQUEST, data: data };
}

export function saveReturnRequest(data) {
    return dispatch => dispatch(saveReturnRequestAct(data));
}

export function saveReturnSuccess(response, data) {
    return {
        type: SAVE_RETURN_SUCCESS,
        data: data,
        response: response
    };
}

export function saveReturnFailure(error) {
    return { type: SAVE_RETURN_FAILURE, error };
}

export function saveReturn(data) {
    data.action = 'SUBMIT_RETURN'
    return dispatch =>
        post(backendWebhook, data).then(
            response => dispatch(saveReturnSuccess(response, data)),
            error => {
                if (error) {
                    dispatch(saveReturnFailure(error));
                }
            }
        );
}

export function fetchReturnRequestAct(data) {
    return { type: FETCH_RETURN_REQUEST, data: data };
}

export function fetchReturnRequest(data) {
    return dispatch => dispatch(fetchReturnRequestAct(data));
}

export function fetchReturnSuccess(response, data) {
    return {
        type: FETCH_RETURN_SUCCESS,
        data: data,
        response: response
    };
}

export function fetchReturnFailure(error) {
    return { type: FETCH_RETURN_FAILURE, error };
}

export function fetchReturn(data) {
    data.action = 'RETRIEVE_RETURN'
    return dispatch =>
        post(backendWebhook, data).then(
            response => dispatch(fetchReturnSuccess(response, data)),
            error => {
                if (error) {
                    dispatch(fetchReturnFailure(error));
                }
            }
        );
}

export function fetchReturnsRequestAct(data) {
    return { type: FETCH_RETURNS_REQUEST, data: data };
}

export function fetchReturnsRequest(data) {
    return dispatch => dispatch(fetchReturnsRequestAct(data));
}

export function fetchReturnsSuccess(response, data) {
    return {
        type: FETCH_RETURNS_SUCCESS,
        data: data,
        response: response
    };
}

export function fetchReturnsFailure(error) {
    return { type: FETCH_RETURNS_FAILURE, error };
}

export function fetchReturns(data) {
    data.action = 'RETRIEVE_RETURNS'
    return dispatch =>
        post(backendWebhook, data).then(
            response => dispatch(fetchReturnsSuccess(response, data)),
            error => {
                if (error) {
                    dispatch(fetchReturnsFailure(error));
                }
            }
        );
}

export function processReturnRequestAct(data) {
    return { type: PROCESS_RETURN_REQUEST, data: data };
}

export function processReturnRequest(data) {
    return dispatch => dispatch(processReturnRequestAct(data));
}

export function processReturnSuccess(response, data) {
    return {
        type: PROCESS_RETURN_SUCCESS,
        data: data,
        response: response
    };
}

export function processReturnFailure(error) {
    return { type: PROCESS_RETURN_FAILURE, error };
}

export function processReturn(data) {
    data.action = 'PROCESS_RETURN'
    return dispatch =>
        post(backendWebhook, data).then(
            response => dispatch(processReturnSuccess(response, data)),
            error => {
                if (error) {
                    dispatch(processReturnFailure(error));
                }
            }
        );
}