import styled from 'styled-components';

export const StyleButton = styled.button`
    height: 100px;
    width: 90%;
    border: 1px solid ${props => props.submitted ? 
        props.theme.colors.styleContainerSubmittedBorder : 
        props.theme.colors.styleBorderInactive};
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    margin: 30px auto 30px;
    background: ${props => props.submitted ? 
        props.theme.colors.styleContainerSubmitted : 
        props.disabled ?
        props.theme.colors.styleButtonDisabled :
        props.theme.colors.white};
    display: flex;
    &:hover {
        background: ${props => props.submitted ? 
            props.theme.colors.styleContainerSubmitted : 
            props.disabled ?
            props.theme.colors.styleButtonDisabled :
            props.theme.colors.styleContainerHover};
        border: 1px solid ${props => props.submitted ? 
            props.theme.colors.styleContainerSubmittedBorder : 
            props.disabled ?
            props.theme.colors.styleBorderInactive :
            props.theme.colors.styleContainerHoverBorder};
        cursor: ${props => props.submitted || props.disabled ? 'default' : 'pointer'};
    }
    &:focus{
        outline: 0;
    }
`;

export const ImgContainer = styled.div`
    height: 84%;
    width: 70px;
    margin-top: 7px;
`;

export const InfoContainer = styled.div`
    width: 75%;
    display: flex;
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
    flex-wrap: wrap;
    justify-content: unset;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        flex-direction: column;
        justify-content: space-between;
        width: 70%;
        height: 85%;
    }
    }
`;

export const Name = styled.h1`
    font-size: ${props => props.theme.typography.infoFontSize};
    color: ${props => props.theme.colors.informationData};
    margin: 0;
    margin-top: auto;
    margin-bottom: auto;
    @media(max-width: ${props => props.theme.screen.breakpoint}) {
        text-align: left;
        margin-top: 5px;
    }
`;

export const InfoLineContainer = styled.div`
    height: 75%;
    margin-top: auto;
    margin-bottom: auto;
    margin: 10px;
    margin-right: 15px;
    @media(max-width: ${props => props.theme.screen.breakpoint}) {
        height: auto;
        margin: 0px;
        margin-right: 0px;
    }
`;