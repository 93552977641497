import * as S from './styles';

export default function VariantTile(props) {

    const vals = {
        attribute_id: props.variantAttribute.attribute.id,
        attribute_name: props.variantAttribute.attribute.name,
        attribute_slug: props.variantAttribute.attribute.slug,
        attribute_value_slug: props.variantData.slug,
        attribute_value_name: props.variantData.name
    };

    const isDisabled = props.isDisabled(vals);

    function handleClick() {
        props.onHandleClick(vals, isDisabled);
    }

    function checkActive(slug, activeVariants) {
        if(activeVariants[slug] === undefined){
            return false;
        }
        else if(activeVariants[slug] !== props.variantData.slug) {
            return false;
        }
        return true;
    }

    return(
        <S.TileButton
            onClick={handleClick}
            disabled={isDisabled}
        >
           {
            props.variantData.image !== null &&
            <S.TileImageContainer>
                <S.Image 
                    disabled={isDisabled}
                    active={checkActive(props.variantAttribute.attribute.slug, props.activeTiles)} 
                    alt='' 
                    src={props.variantData.image}
                />
            </S.TileImageContainer>  
            }
            {
                props.variantData.image === null &&
                <S.TileNameContainer
                    disabled={isDisabled}
                    active={checkActive(props.variantAttribute.attribute.slug, props.activeTiles)}
                >
                    <S.Name disabled={isDisabled}>{props.variantData.name}</S.Name>
                </S.TileNameContainer>
            }
        </S.TileButton>
    );
}