import React from 'react';

import { Button } from '../../atoms/Button';
import { FormField } from '../../atoms/FormField';
import { Loader } from '../../atoms/Loader';

import { commonMessages, privacyPolicyUrl, returnPolicyUrl } from '../../../config';
import * as S from './styles';

export default class OrderForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            orderNumber: '',
            zip: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.checkFormInput = this.checkFormInput.bind(this);
    }

    checkFormInput(formList) {
        for (let i = 0; i < formList.length; i++) {
            if (formList[i].length === 0) {
                return true;
            }
        }
    }

    handleSubmit() {
        const baseFormData = {
            email: this.state.email,
            orderNumber: this.state.orderNumber,
            zip: this.state.zip
        }
        this.props.handleSubmit(baseFormData)
    }

    onChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked :
            target.type === 'select-multiple' ? 
            Array.from(target.selectedOptions, option => option.value)
                : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    render() {
        const loading = this.props.loading;
        return (
            <S.OrderFormContainer>
                <S.Header>{commonMessages.orderForm.header}</S.Header>
                <S.Information>{commonMessages.orderForm.information}</S.Information>
                <S.Information>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={returnPolicyUrl}>
                        {commonMessages.orderForm.returnPolicyLabel}
                    </a>
                </S.Information>
                <S.Error>{this.props.error}</S.Error>
                <S.FormContainer>
                    <FormField
                        placeholder={commonMessages.orderForm.email}
                        onChange={this.onChange}
                        name={"email"}
                        value={this.state.email}
                        disabled={loading}
                    />

                    <FormField
                        placeholder={commonMessages.orderForm.orderNumber}
                        onChange={this.onChange}
                        name={"orderNumber"}
                        value={this.state.orderNumber}
                        disabled={loading}
                    />

                    <FormField
                        placeholder={commonMessages.orderForm.zip}
                        onChange={this.onChange}
                        name={"zip"}
                        value={this.state.zip}
                        disabled={loading}
                    />
                </S.FormContainer>

                <S.SubmitContainer>
                    <S.SubmitButtonContainer>
                        <Button
                            label={commonMessages.orderForm.buttonStart}
                            handleSubmit={this.handleSubmit}
                            disabled={this.checkFormInput([
                                this.state.email,
                                this.state.orderNumber,
                                this.state.zip
                            ]) || loading}
                        />
                    </S.SubmitButtonContainer>
                    {
                        loading &&
                        <S.LoaderContainer>
                            <Loader />
                        </S.LoaderContainer>
                    }
                </S.SubmitContainer>
                <S.Information>{commonMessages.orderForm.privacyPolicy}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={privacyPolicyUrl}>
                        {commonMessages.orderForm.privacyPolicyLabel}
                    </a>
                </S.Information>
            </S.OrderFormContainer>
        );
    }
}