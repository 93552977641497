import styled from 'styled-components';

export const Input = styled.input`
    font-weight: 600;
    margin: 0 auto;
    padding-left: 2px;
    border: none;
    color: ${props => props.disabled ? props.theme.colors.textDisabled : 
        props.theme.colors.black};
    height: 17px;
    width: 30px;
    background: ${props => props.disabled ? props.theme.colors.formDisabled : 
        props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.formOutline};
    ::placeholder {
        color: ${props => props.theme.colors.placeholderText};
    }
    &:focus{
        outline: 0;
    }
`;

export const Positive = styled(Input)`
    background: ${props => props.disabled ? props.theme.colors.formDisabled : 
        props.theme.colors.positiveBackground};
    border: 1px solid ${props =>props.disabled ? props.theme.colors.formOutline : 
        props.theme.colors.positiveBorder};
`;

export const Negative = styled(Input)`
background: ${props => props.disabled ? props.theme.colors.formDisabled : 
    props.theme.colors.negativeBackground};
    border: 1px solid ${props => props.disabled ? props.theme.colors.formOutline : 
        props.theme.colors.negativeBorder};
`;

export const Neutral = styled(Input)`

`;