import * as S from './styles';

export default function Loader() {
    return(
        <S.LoaderContainer>
            <S.LoaderFull>

            </S.LoaderFull>
        </S.LoaderContainer>
    );
}