import * as S from './styles';

export default function FormField(props) {

    const Input = props.nonConsumer ? S.InputNonConsumer : S.InputPrimary;

    return (
        <Input
            name={props.name}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
        />
    );
}