import React from 'react';

import { ReturnLine } from '../ReturnLine';

import { commonMessages } from '../../../config';

import * as S from './styles';

export default class ProcessType extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(invalid, line) {
        this.props.handleChange(invalid, line);
    }

    render() {
        const type = this.props.typeData;
        const lines = this.props.lines;
        const subheaders = commonMessages.processTable.subheaders;
        const subheaderList = [], lineList = [];

        for (const key in subheaders) {
            subheaderList.push(
                <S.SubHeader key={key}>{subheaders[key]}</S.SubHeader>
            );
        }

        for (let i = 0; i < lines.length; i++) {
            lineList.push(
                <ReturnLine
                    key={lines[i].id}
                    data={lines[i]}
                    type={type}
                    handleChange={this.handleChange}
                />
            );
        }

        const TypeContainer = this.props.return ? S.ReturnContainer : S.ExchangeContainer;

        return (
            <TypeContainer>
                <S.Header>{type.header}</S.Header>
                <S.SubHeaderContainer>
                    {subheaderList}
                </S.SubHeaderContainer>
                <S.LineContainer>
                    {lineList}
                </S.LineContainer>
            </TypeContainer>
        )
    }
}