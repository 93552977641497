import styled from 'styled-components';

export const ILContainer = styled.div`
    height: 20px;
    display: flex;
`;

export const Label = styled.h4`
    color: ${props => props.theme.colors.informationText};
    font-size: ${props => props.theme.typography.infoFontSize};
    font-weight: 500;
    margin: auto 0px auto 0px;
`;

export const Data = styled.a`
    color: ${props => props.theme.colors.informationData};
    font-size: ${props => props.theme.typography.infoFontSize};
    font-weight: 600;
    margin: auto 0px auto 0px;
    &:hover {
        color: ${props => props.theme.colors.black};
    }
    &:focus {
        outline: none;
        color: ${props => props.theme.colors.black}

    }
`;

export const DataContainer = styled.div`
    display: flex;
`;

export const Space = styled.div`

`;