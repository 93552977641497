import { Switch, Route } from 'react-router-dom';

import { Order } from '../../views/Order';
import { Process } from '../../views/Process';

import * as paths from './paths';

function Routes() {
    return(
        <Switch>
            <Route exact path={paths.orderUrl} component={Order} />
            <Route path={paths.processOrderUrl} component={Process} />
        </Switch>
    );
}

export default Routes;