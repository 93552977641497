import styled from 'styled-components';

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  bottom: 0%;
  left: 50%;
  right: 0%;
  background-color: ${props => props.theme.colors.white};
  z-index: 99;
`;

export const LoaderFull = styled.div`
    border: 2.5px solid ${props => props.theme.colors.loaderFullBorder};
    border-top: 2.5px solid ${props => props.theme.colors.loaderSpinner};
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
`;