import * as S from './styles';

export default function TextArea(props) {
    return (
        <S.TextArea 
            value={props.value}
            onChange={props.onChange}
            name={props.name}
        />
    );
}