export const fetchOrderInitial = {
    isFetching: false,
    items: [],
    error: null,
    count: 0
};

export function fetchOrderRequest(state) {
    return Object.assign({}, state, {
        isFetching: true,
        error: null,
    });
}

export function fetchOrderSuccess(state, action) {
    return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        response: action.response.data,
        error: null,
    });
}

export function fetchOrderFailure(state, action) {
    return Object.assign({}, state, {
        isFetching: false,
        error: action.error.response.data.error
    });
}

export const fetchOrderFormInitial = {
    isFetching: false,
    items: [],
    error: null,
    count: 0
};

export function fetchOrderFormRequest(state) {
    return Object.assign({}, state, {
        isFetching: true,
        error: null,
    });
}

export function fetchOrderFormSuccess(state, action) {
    return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        response: action.response.data,
        error: null,
    });
}

export function fetchOrderFormFailure(state, action) {
    return Object.assign({}, state, {
        isFetching: false,
        error: action.error.response.data.error
    });
}