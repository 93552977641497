import styled from 'styled-components';

export const Divider = styled.div`
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid ${props => props.theme.colors.divider};
    @media(max-width: ${props => props.theme.screen.breakpoint}) {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;