import styled from 'styled-components';

export const ReturnModal = styled.div`
    height: 600px;
    width: 700px;
    background: ${props => props.theme.colors.containerBackground};
    margin: auto;
    margin-top: 30px;
    border: 1px solid ${props => props.theme.colors.border};
    border-radius: ${props => props.theme.components.borderRadiusConsumer};
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        background: ${props => props.theme.colors.white};
        border: none;
        margin-top: 0px;
        height: auto;
        width: auto;
        border: none;
        
    }
    display: flex;
    flex-direction: column;
`;

export const ContainerT = styled.div`

`;

export const ContainerB = styled.div`
    display: flex;
    height: 100%;
    margin: auto;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        flex-direction: column;
        width: 95vw;
        height: auto;
    }
`;

export const ContainerL = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ProductInfo = styled.div`
    height: 164px;
    width: 206px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        height: 50px;
        margin-top: -20px;
        flex-wrap: wrap;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const ContainerR = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ModalHeader = styled.h1`
    font-size: 1.25rem;
    font-weight: 500;
    color: #707070;
    margin-left: 9px;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        text-align: center;
        margin-left: 0px;
    }
`;

export const CloseContainer = styled.div`
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: 15px;
    margin-top: 15px;
    svg{
        height:100%;
        width: 100%;
        &:hover {
            cursor: pointer;
            fill: ${props => props.theme.colors.iconHover};
        }
    }
`;

export const CloseButton = styled.button`
    padding: 0px;
    background: none;
    border: none;
    &:focus {
        outline: none;
    }
`;

export const SectionContainer = styled.div`
`;

export const SectionHeader = styled.h3`
    font-size: 1rem;
    font-weight: 500;
    color: ${props => props.theme.colors.informationText};
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
`;

export const ImageContainer = styled.div`
    width: 220px;
    height: 270px;
    margin: 0px;
    margin-right: 40px;
    margin-top: 15px;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        margin: auto;
        margin-bottom: 50px;
        margin-top: 50px;
        
    }
`;

export const ReasonContainer = styled.div`
    width: 325px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
    }
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
    &::-webkit-scrollbar-track {
        background: ${props => props.theme.colors.scrollBarTrack};
        border-radius: ${props => props.theme.components.borderRadiusConsumer};
    }
    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.scrollBarHandle};
        border-radius: ${props => props.theme.components.borderRadiusConsumer};
    }
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        width: 100%;
        overflow-y: visible;
        height: auto;
        margin: auto;
    }
`;

export const ButtonContainer = styled.div`
    margin-bottom: 15px;
    width: 246px;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        margin-top: 15px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const RHContainer = styled.div`
    margin-top: 0px;
    padding: 0px;
    width: 305px;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
      width: auto;
    }
`;

export const RBContainer = styled.div`
    padding: 11px;
    max-height: 440px;
    min-height: 238px;
    display: flex;
    flex-direction: column;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        height: auto;
        max-height: fit-content;
        min-height: auto;
      }
`;

export const FormContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    width: 55px;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        display: flex;
        width: 55px;
      }
`;

export const MessageContainer = styled.div`
    height: 25px;
    margin-top: -2px;
    margin-left: 50px;
`;

export const VariantContainer = styled.div`
    height: 265px;
    width: 247px;
    margin-bottom: 20px;
    @media(max-width: ${props => props.theme.screen.breakpoint}){
        width: auto;
        height: auto;
    }
`;