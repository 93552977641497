import { post } from '../services';
import { backendWebhook } from '../../config';
import {
    FETCH_ORDER_REQUEST,
    FETCH_ORDER_SUCCESS,
    FETCH_ORDER_FAILURE,
    FETCH_ORDER_FORM_REQUEST,
    FETCH_ORDER_FORM_SUCCESS,
    FETCH_ORDER_FORM_FAILURE
} from './constants';

export function fetchOrderRequestAct(data) {
    return { type: FETCH_ORDER_REQUEST, data: data };
}

export function fetchOrderRequest(data) {
    return dispatch => dispatch(fetchOrderRequestAct(data));
}

export function fetchOrderSuccess(response, data) {
    return {
        type: FETCH_ORDER_SUCCESS,
        data: data,
        response: response
    };
}

export function fetchOrderFailure(error) {
    return { type: FETCH_ORDER_FAILURE, error };
}

export function fetchOrder(data) {
    data.action = 'RETRIEVE_ORDER'
    return dispatch =>
        post(backendWebhook, data).then(
            response => dispatch(fetchOrderSuccess(response, data)),
            error => {
                if (error) {
                    dispatch(fetchOrderFailure(error));
                }
            }
        );
}

export function fetchOrderFormRequestAct(data) {
    return { type: FETCH_ORDER_FORM_REQUEST, data: data };
}

export function fetchOrderFormRequest(data) {
    return dispatch => dispatch(fetchOrderFormRequestAct(data));
}

export function fetchOrderFormSuccess(response, data) {
    return {
        type: FETCH_ORDER_FORM_SUCCESS,
        data: data,
        response: response
    };
}

export function fetchOrderFormFailure(error) {
    return { type: FETCH_ORDER_FORM_FAILURE, error };
}

export function fetchOrderForm(data) {
    data.action = 'RETRIEVE_ORDER_FORM'
    return dispatch =>
        post(backendWebhook, data).then(
            response => dispatch(fetchOrderFormSuccess(response, data)),
            error => {
                if (error) {
                    dispatch(fetchOrderFormFailure(error));
                }
            }
        );
}