import React from 'react';

import { InformationLine } from '../../atoms/InformationLine';

import { returnCodePrefix, commonMessages } from '../../../config';
import { formatReturnTypeDisplay } from '../../../util';

import * as S from './styles';

export default class UnprocessedReturn extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        const processFormData = {
            code: returnCodePrefix + this.props.returnData.order_number
        }
        this.props.handleSubmit(processFormData);
    }

    render(){
        return(
            <S.UnprocessedReturnContainer
                onClick={this.handleSubmit}
            >
                <S.FlexContainer>
                    <S.SubHeader>
                        {commonMessages.unprocessedReturns.orderNumber}
                        {this.props.returnData.order_number}
                    </S.SubHeader>
                    <S.InfoLineContainer>
                        <InformationLine 
                            label={commonMessages.unprocessedReturns.returnType}
                            data={formatReturnTypeDisplay(this.props.returnData.return_type)}
                        />
                    </S.InfoLineContainer>
                </S.FlexContainer>
            </S.UnprocessedReturnContainer>
        )
    }
}