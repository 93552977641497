import React from 'react';

import { Image } from '../../atoms/Image';
import { InformationLine } from '../../atoms/InformationLine';

import { commonMessages } from '../../../config';
import { currencyFormat } from '../../../util';

import * as S from './styles';

export default class Style extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.checkSubmitted = this.checkSubmitted.bind(this);
    }

    handleClick() {
        this.props.onHandleClick(this.props.styleData);
    }

    checkSubmitted(id) {
        for (let i = 0; i < this.props.submittedData.length; i++) {
            if (this.props.submittedData[i].id === id) {
                return true;
            }
        }
        return false;
    }

    getReturnQuantity(id) {
        for (let i = 0; i < this.props.submittedData.length; i++) {
            if (this.props.submittedData[i].id === id) {
                return this.props.submittedData[i].quantity;
            }
        }
        return "-";
    }

    render() {
        return (
            <S.StyleButton
                submitted={this.checkSubmitted(this.props.styleData.id)}
                onClick={this.handleClick}
                disabled={
                    this.checkSubmitted(this.props.styleData.id) ||
                    this.props.styleData.quantity_fulfilled - this.props.styleData.quantity_returned <= 0
                }
            >
                <S.ImgContainer>
                    <Image src={this.props.styleData.img} />
                </S.ImgContainer>

                <S.InfoContainer>
                        <S.InfoLineContainer>
                            <InformationLine
                                label={commonMessages.style.name}
                                data={this.props.styleData.product_name}
                            />
                        </S.InfoLineContainer>
                        <S.InfoLineContainer>
                            <InformationLine
                                label={commonMessages.style.size}
                                data={this.props.styleData.size}
                            />
                        </S.InfoLineContainer>
                        <S.InfoLineContainer>
                            <InformationLine
                                label={commonMessages.style.color}
                                data={this.props.styleData.color}
                            />
                        </S.InfoLineContainer>
                        <S.InfoLineContainer>
                            <InformationLine
                                label={commonMessages.style.quantity}
                                data={this.props.styleData.quantity_fulfilled.toString()}
                            />
                        </S.InfoLineContainer>
                        <S.InfoLineContainer>
                            <InformationLine
                                label={commonMessages.style.availableQuantity}
                                data={(this.props.styleData.quantity_fulfilled - this.props.styleData.quantity_returned).toString()}
                            />
                        </S.InfoLineContainer>

                        <S.InfoLineContainer>
                            <InformationLine
                                label={commonMessages.style.returnQuantity}
                                data={this.getReturnQuantity(this.props.styleData.id)}
                            />
                        </S.InfoLineContainer>
                        <S.InfoLineContainer>
                            <InformationLine
                                label={commonMessages.style.price}
                                data={currencyFormat.format(this.props.styleData.unit_price_gross_amount)}
                            />
                        </S.InfoLineContainer>
                </S.InfoContainer>
            </S.StyleButton>
        );
    }
}