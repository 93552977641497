import React from 'react';

import { Dropdown } from '../../atoms/Dropdown';
import { FormFieldNumber } from '../../atoms/FormFieldNumber';

import { commonMessages, commonTypes, rejectReasons } from '../../../config';

import * as S from './styles';

export default class ReturnLine extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            acceptQuantity: 0,
            rejectQuantity: 0,
            restockQuantity: 0,
            exchangeQuantity: 0,
            rejectReason: null,
            raiseErrorPrimary: false
        }

        this.onChange = this.onChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);

    }

    handleDropdownChange(name, data) {
        Promise.all([
            this.setState({
                [name]: data
            }),
        ]).then(() => {
            if (parseInt(this.state.acceptQuantity) +
                parseInt(this.state.rejectQuantity) <=
                parseInt(this.props.data.quantity_returned) -
                parseInt(this.props.data.quantity_accepted) -
                parseInt(this.props.data.quantity_rejected) &&
                parseInt(this.state.restockQuantity) <=
                parseInt(this.state.acceptQuantity) &&
                parseInt(this.state.restockQuantity) >= 0) {
                const processLine = {
                    id: this.props.data.id,
                    rh_id: this.props.data.rh_id,
                    return_sku: this.props.data.return_sku,
                    return_type: this.props.data.return_type,
                    exchange_sku: this.props.data.exchange_sku,
                    quantity_available: this.props.data.quantity_returned -
                        this.props.data.quantity_accepted -
                        this.props.data.quantity_rejected,
                    quantity_accepted: parseInt(this.state.acceptQuantity),
                    quantity_rejected: parseInt(this.state.rejectQuantity),
                    quantity_restock: parseInt(this.state.restockQuantity),
                    quantity_exchange: parseInt(this.state.exchangeQuantity),
                    reject_reason: this.state.rejectReason
                }
                this.props.handleChange(false, processLine);
                this.setState({
                    raiseErrorPrimary: false,
                });
            }
            else {
                this.props.handleChange(true, this.props.data.id);
                this.setState({
                    raiseErrorPrimary: true,
                });
            }
        });
    }

    onChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked :
            target.type === 'select-multiple' ?
                Array.from(target.selectedOptions, option => option.value)
                : target.value;
        const name = target.name;
        Promise.all([
            this.setState({
                [name]: value
            }),
        ]).then(() => {
            if (parseInt(this.state.acceptQuantity) +
                parseInt(this.state.rejectQuantity) <=
                parseInt(this.props.data.quantity_returned) -
                parseInt(this.props.data.quantity_accepted) -
                parseInt(this.props.data.quantity_rejected) &&
                parseInt(this.state.restockQuantity) <=
                parseInt(this.state.acceptQuantity) &&
                parseInt(this.state.restockQuantity) >= 0) {
                const processLine = {
                    id: this.props.data.id,
                    rh_id: this.props.data.rh_id,
                    return_sku: this.props.data.return_sku,
                    return_type: this.props.data.return_type,
                    exchange_sku: this.props.data.exchange_sku,
                    quantity_available: this.props.data.quantity_returned -
                        this.props.data.quantity_accepted -
                        this.props.data.quantity_rejected,
                    quantity_accepted: parseInt(this.state.acceptQuantity),
                    quantity_rejected: parseInt(this.state.rejectQuantity),
                    quantity_restock: parseInt(this.state.restockQuantity),
                    quantity_exchange: parseInt(this.state.exchangeQuantity),
                    reject_reason: this.state.rejectQuantity <= 0 ? null : this.state.rejectReason
                }
                this.props.handleChange(false, processLine);
                this.setState({
                    raiseErrorPrimary: false,
                });
            }
            else {
                this.props.handleChange(true, this.props.data.id);
                this.setState({
                    raiseErrorPrimary: true,
                });
            }
        });
    }

    render() {
        const dropDownList = [];
        for (const key in rejectReasons) {
            dropDownList.push([rejectReasons[key].label, rejectReasons[key].shortCode]);
        }

        const isReturn = this.props.type.typeName === commonTypes.return.typeName;
        const availableQuantity =
            this.props.data.quantity_returned -
            this.props.data.quantity_accepted -
            this.props.data.quantity_rejected;
        const TypeContainer = isReturn ? S.Line : S.ExchangeLine;
        const DataContainer = isReturn ? S.Data : S.ExchangeData;
        const FormDataContainer = isReturn ? S.FormData : S.ExchangeFormData;

        const fullDataList = [
            <DataContainer
                invalid={this.state.raiseErrorPrimary}
                key={"styleKey"}
            >
                {this.props.data.style}
            </DataContainer>,
            <DataContainer
                invalid={this.state.raiseErrorPrimary}
                key={"colorKey"}
            >
                {this.props.data.color}
            </DataContainer>,
            <DataContainer
                invalid={this.state.raiseErrorPrimary}
                key={"sizeKey"}
            >
                {this.props.data.size}
            </DataContainer>,
            <DataContainer
                invalid={this.state.raiseErrorPrimary}
                key={"qtyKey"}
            >
                {this.props.data.quantity_returned}
            </DataContainer>,
            <DataContainer
                invalid={this.state.raiseErrorPrimary}
                key={"availableQtyKey"}
            >
                {availableQuantity}
            </DataContainer>,
            <FormDataContainer key={"acceptQtyKey"}>
                <FormFieldNumber
                    positive
                    placeholder={0}
                    name={"acceptQuantity"}
                    value={this.state.acceptQuantity}
                    onChange={this.onChange}
                    disabled={availableQuantity === 0}
                />
            </FormDataContainer>,
            <FormDataContainer key={"rejectQtyKey"}>
                <FormFieldNumber
                    negative
                    placeholder={0}
                    name={"rejectQuantity"}
                    value={this.state.rejectQuantity}
                    onChange={this.onChange}
                    disabled={availableQuantity === 0}
                />
            </FormDataContainer>,
            <FormDataContainer
                invalid={this.state.raiseErrorPrimary}
                key={"rejectReasonKey"}
            >
                <Dropdown
                    name={"rejectReason"}
                    label={commonMessages.dropdown.label}
                    options={dropDownList}
                    onChange={this.handleDropdownChange}
                    returnToLabel={this.state.rejectQuantity <= 0}
                    disabled={availableQuantity === 0 || parseInt(this.state.rejectQuantity) <= 0}
                />
            </FormDataContainer>,
            <FormDataContainer key={"restockQtyKey"}>
                <FormFieldNumber
                    placeholder={0}
                    name={"restockQuantity"}
                    value={this.state.restockQuantity}
                    onChange={this.onChange}
                    disabled={availableQuantity === 0 || parseInt(this.state.acceptQuantity) <= 0}
                />
            </FormDataContainer>
        ]

        const exchangeDataList = [
            <S.InfoLineContainer key={"exchangeSKULabel"}>
                <S.Label>{commonMessages.processTable.exchange}</S.Label>
            </S.InfoLineContainer>,
            <DataContainer
                key={"colorKey"}
            >
                {this.props.data.exchangeColor}
            </DataContainer>,
            <DataContainer
                key={"sizeKey"}
            >
                {this.props.data.exchangeSize}
            </DataContainer>,
            <DataContainer
                key={"qtyPlaceholderKey"}
            >

            </DataContainer>,
            <DataContainer
                key={"availableQtyPlaceholderKey"}
            >

            </DataContainer>,
            <S.InfoLineContainer key={"exchangeQtyLabel"}>
                <S.Label>{commonMessages.processTable.exchangeQuantity}</S.Label>
            </S.InfoLineContainer>,
            <FormDataContainer key={"rejectQtyKey"}>
                <FormFieldNumber
                    placeholder={0}
                    name={"exchangeQuantity"}
                    value={this.state.exchangeQuantity}
                    onChange={this.onChange}
                    disabled={availableQuantity === 0 || parseInt(this.state.acceptQuantity) <= 0}
                />
            </FormDataContainer>,
            <DataContainer
                invalid={this.state.raiseErrorPrimaryPrimary}
                key={"rejectReasonPlaceholderKey"}
            >

            </DataContainer>,
            <FormDataContainer key={"restockQtyPlaceholderKey"}></FormDataContainer>
        ]

        return (
            <TypeContainer
                disabled={availableQuantity === 0}
            >
                {fullDataList}
                {
                    !isReturn &&
                    <S.ExchangeContainer>
                        {exchangeDataList}
                    </S.ExchangeContainer>
                }
                <S.BottomContainer>
                    <S.ReturnReason>
                        <S.ReturnReasonLabelContainer>
                            <S.ReturnReasonLabel>{commonMessages.processTable.returnReason}</S.ReturnReasonLabel>
                        </S.ReturnReasonLabelContainer>
                        <S.ReturnReasonData>{this.props.data.return_reason}</S.ReturnReasonData>
                    </S.ReturnReason>
                </S.BottomContainer>
            </TypeContainer>
        );
    }
}