import React from 'react';
import { connect } from 'react-redux';

import { ProcessForm } from '../../components/organisms/ProcessForm';
import { UnprocessedReturns } from '../../components/organisms/UnprocessedReturns';
import { ProcessTable } from '../../components/organisms/ProcessTable';
import { LoaderFull } from '../../components/atoms/LoaderFull';
import { commonMessages } from '../../config';

import { fetchReturn, fetchReturns } from '../../redux/return/actions';
import * as S from './styles';

class Process extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            loading: false,
            error: undefined
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFormRedirect = this.handleFormRedirect.bind(this);
    }

    componentDidMount(){
        const { dispatch } = this.props;
        this.setState({ loading: true });
        Promise.all([
            dispatch(fetchReturns({})),
        ]).then(() => {
            if(this.props.returnsData !== undefined){
                this.setState({
                    loading: false
                });
            } 
        });
    }

    handleSubmit(processFormData) {
        const { dispatch } = this.props;
        this.setState({ loading: true });
        Promise.all([
            dispatch(fetchReturn(processFormData)),
        ]).then(() => {
            if(!this.props.errorProp && this.props.returnData !== undefined){
                this.setState({
                    redirect: true,
                    loading: false
                });
            } 
            else if (this.props.returnData === undefined) {
                this.setState({
                    error: commonMessages.processForm.error,
                    loading: false
                })
            }
            else {
                this.setState({
                    loading: false
                });
            }
        });
    }

    handleFormRedirect() {
        this.setState ({ redirect: false });
    }

    render(){
        const error = this.props.errorProp || this.state.error;
        const loading = this.state.loading;
        const redirect = this.state.redirect;
        if (redirect) {
            return (
                <ProcessTable
                    handleFormRedirect={this.handleFormRedirect}
                />
            );
        }
        if(loading) {
            return(
                <LoaderFull />
            )
        }
        return (
            <S.FlexContainer>
                <UnprocessedReturns
                    handleSubmit={this.handleSubmit}
                    returnsData={this.props.returnsData}
                />
                <ProcessForm
                    handleSubmit={this.handleSubmit}
                    error={error}
                    stateError={this.state.error}
                    loading={loading}
                />
            </S.FlexContainer>
        );
    }
}

function mapStateToProps(state) {
    const { orderReturnFetch } = state;
    const { orderReturnsFetch } = state;
    const { orderReturnProcess } = state;
    var returnData = undefined;
    var returnsData = undefined;
    var errorProp = orderReturnFetch.error;   
    if (orderReturnFetch) {
        const { response } = orderReturnFetch;
        if(response) {
            returnData = response.return;
        }
    }
    if (orderReturnsFetch) {
        if(orderReturnsFetch.response) {
            returnsData = orderReturnsFetch.response.returns;
        }
    }
    return {
        returnData,
        returnsData,
        orderReturnProcess,
        errorProp
    }
}


const processContainer = connect(mapStateToProps)(Process);
export { processContainer as Process };