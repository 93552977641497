import React from 'react';

import { UnprocessedReturn } from '../../molecules/UnprocessedReturn';

import { commonMessages } from '../../../config';

import * as S from './styles';

export default class UnprocessedReturns extends React.Component {
    render(){
        const returnsData = this.props.returnsData ? this.props.returnsData : [];
        var unprocessedReturns = [];
        for(let i = 0; i < returnsData.length; i++) {
            unprocessedReturns.push(
                <S.URContainer key={returnsData[i].order_number}>
                    <UnprocessedReturn
                        returnData={returnsData[i]}
                        handleSubmit={this.props.handleSubmit}
                    />
                </S.URContainer>
            )
        }
        return(
            <S.UnprocessedReturnsContainer>
                <S.Header>{commonMessages.unprocessedReturns.header}</S.Header>
                <S.Body>
                    {unprocessedReturns}
                </S.Body>
            </S.UnprocessedReturnsContainer>
        );
    }
}